import { HeaderLink, LogoTypography, colors } from '../../theme.js';
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as api from "../../util/api";
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    flewGrow: 1
  },
  boldText: {
    fontWeight: 700
  },
  inactiveText: {
    fontWeight: 700
  },
  appBar: {
    boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
    width: '100%'
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    disableScrollLock={true}
    {...props}
  />
));

export default function Header() {
  // Recover selected tab.
  const match = useLocation();

  // Ensure we have a token.
  const token = api.getDecodedAccessToken();
  if (!token) {
    window.location.href = "/signin";
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();

  const initials = (token.name.split(' ')[0] || '').substr(0, 1) + (token.name.split(' ')[1] || '').substr(0, 1);

  const handleAccountMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = (event) => {
    handleMenuClose();
    api.removeAccessToken();
    window.location.href = "/signin";
  };

  const handleChangePassword = (event) => {
    handleMenuClose();
    history.push("/change-password")
  };

  const menuId = 'primary-search-account-menu';

  const links = [
    { label: 'Home', location: '/home' },
    { label: 'Profile', location: '/profile' },
    { label: 'Marketing', location: '/marketing' },
    { label: 'Training', location: '/training' },
    { label: 'Contact', location: '/contact' }
  ];

  return (
    <div className={classes.root}>
      <table className={classes.appBar}>
        <tbody>
          <tr>
            <td style={{width: 200, minWidth: 200, maxWidth: 200}}>
              <LogoTypography style={{marginTop: '-4px'}}>
                <span style={{color: colors.accent}}>Geo</span> Partner Portal
              </LogoTypography>
            </td>
            <td style={{textAlign: 'center'}}>
              <div style={{maxWidth: 1096, margin: '0 auto', textAlign: 'left', marginTop: '-1px'}}>
                {links.map((l) => (
                  <HeaderLink key={l.location} color={l.location === match.pathname ? 'primary' : 'secondary'} style={l.location === match.pathname ? { fontWeight: 700 } : {}} component={RouterLink} to={`${l.location}`}>{l.label}</HeaderLink>
                ))}
              </div>
            </td>
            <td style={{width: 64, minWidth: 64, maxWidth: 64, textAlign: 'right', paddingRight: 10}}>
              <div>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleAccountMenuOpen}
                  color="inherit">
                  <Avatar>{initials}</Avatar>
                </IconButton>
                <StyledMenu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    style={{marginTop: 40}}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                  >
                  <MenuItem onClick={handleChangePassword} disabled>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={`Signed in as ${token.name}`} />
                  </MenuItem>
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Icon>lock</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </MenuItem>
                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                      <Icon>logout</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                  </MenuItem>
                </StyledMenu>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
