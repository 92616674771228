import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { AccentTypography, Container, ContentContainer, PageContainer, TextButton } from '../../theme.js';

const useStyles = makeStyles((theme) => ({
  subHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

export default function Marketing() {
  const classes = useStyles();

  return (
    <Container>
      <ContentContainer>
        <PageContainer>
          <AccentTypography variant="h1">Marketing Assets</AccentTypography>
          <Typography className={classes.subHeader}>As a partner, there are loads of ways you can promote GeoNext to your customers. Our most successful partners have links from their website, blog, social media pages, email signatures and more.</Typography>
          <Typography className={classes.subHeader}>To help, we’ve developed a range of marketing assets that you can use across your various platforms and channels. Please access them in the Partner Handbook below:</Typography>
          <TextButton className={classes.spacedTopx2} color="primary" target='_blank' href='/resources/GeoNext Partner Handbook.pdf'>GeoNext Partner Handbook</TextButton><br/>
          <Typography className={classes.subHeader}>To schedule time with our Partner Manager on how best to utilise these marketing assets, please <Link target='_blank' href='https://calendly.com/geo-partners/30min'>schedule a time here.</Link></Typography>
        </PageContainer>
      </ContentContainer>
    </Container>
  );
}
