import { Container, ContentContainer, PageContainer, ContainedButton } from '../../theme.js';
import { makeStyles } from '@material-ui/core/styles';
import Auth from '@aws-amplify/auth'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from "../../util/config";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  formContainer: {
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    maxWidth: 280,
    textAlign: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const STATES = {
  WAITING_FOR_EMAIL_ADDRESS: 0,
  WAITING_FOR_VERTIFICATION_CODE: 1,
  WAITING_FOR_USER_CONFIRMATION: 2
}

export default function ForgotPassword() {
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [state, setState] = React.useState(STATES.WAITING_FOR_EMAIL_ADDRESS);
  const [verificationCode, setVertificationCode] = React.useState('');
  const [verificationCodeDestination, setVerificationCodeDestination] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [message, setMessage] = React.useState({content: '', severity: 'success'});
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const usernameError = (username || '').length < 1;
  const verificationCodeError = (verificationCode || '').length < 1;
  const passwordError = (password || '').length < 1;
  const comfirmPasswordError = (confirmPassword || '').length < 1;

  Auth.configure({
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(prevState => ({content: '', severity: prevState.severity}));
  };

  const handleSendVerificationCode = (event, reason) => { 
    event.preventDefault();
    setSubmitted(true);
    if (usernameError) {
      setMessage({content: "Please enter your email address", severity: "error"});
      return;
    }
    setLoading(true);
    Auth.forgotPassword(username)
    .then(function (data) {
      setSubmitted(false);
      setVerificationCodeDestination(data.CodeDeliveryDetails.Destination)
      setState(STATES.WAITING_FOR_VERTIFICATION_CODE);
    })
    .catch(function (error) {
      setMessage({content: "There was a problem resetting your password. " + error.message, severity: "error"});
    }).then(function () {
      setLoading(false);
    })
  };

  const handleSetNewPassword = (event, reason) => { 
    event.preventDefault();
    setSubmitted(true);
    if (verificationCodeError) {
      setMessage({content: "Please enter the verification code sent to your inbox", severity: 'error'});
      return;
    }
    if (passwordError) {
      setMessage({content: "Please enter your new password", severity: 'error'});
      return;
    }
    if (password !== confirmPassword) {
      setMessage({content: 'Passwords do not match', severity: 'error'});
      return;
    }
    setLoading(true);
    Auth.forgotPasswordSubmit(username, verificationCode, password)
    .then(function (data) {
      setSubmitted(false);
      setState(STATES.WAITING_FOR_USER_CONFIRMATION);
    })
    .catch(function (error) {
      console.log(error);
      setMessage({content: "There was a problem resetting your password. " + error.message, severity: "error"});
    }).then(function () {
      setLoading(false);
    })
  };

  return (
    <Container>
      <ContentContainer>
        <PageContainer style={{textAlign: 'center'}}>
          <Snackbar open={message.content.length > 0} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message.severity}>
              <Typography>{message.content}</Typography>
            </MuiAlert>
          </Snackbar>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {state === STATES.WAITING_FOR_EMAIL_ADDRESS ? (
            <div>
              <Typography variant="h2">Forgot your password?</Typography>
              <form noValidate autoComplete="off" className={classes.formContainer}>
                <Grid container className={classes.form} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Please enter your email address</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth required id="username" label="Email Address" error={submitted && usernameError} variant="outlined" value={username} onInput={e=>setUsername(e.target.value)} />
                  </Grid>
                  <Grid item xs={12}>
                    <ContainedButton type="submit" variant="contained" color="primary" fullWidth onClick={handleSendVerificationCode}>Next</ContainedButton>
                  </Grid>
                </Grid>
              </form>
            </div>
          ) : null}
          {state === STATES.WAITING_FOR_VERTIFICATION_CODE ? (
          <div>
            <Typography variant="h2">Forgot your password?</Typography>
            <form noValidate autoComplete="off" className={classes.formContainer}>
              <Grid container className={classes.form} spacing={2}>
                <Grid item xs={12}>
                  <Typography>A vertification code was sent to <b>{verificationCodeDestination}.</b></Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography>Please enter the vertification code followed by your new password.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required id="verificationCode" label="Verification Code" error={submitted && verificationCodeError} variant="outlined" value={verificationCode} onInput={e=>setVertificationCode(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required id="password" type="password" label="New Password" variant="outlined" value={password} error={submitted && passwordError} onInput={e=>setPassword(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required id="confirmPassword" type="password" label="Confirm New Password" variant="outlined" value={confirmPassword} error={submitted && comfirmPasswordError} onInput={e=>setConfirmPassword(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <ContainedButton type="submit" variant="contained" color="primary" fullWidth onClick={handleSetNewPassword}>Set New Password</ContainedButton>
                </Grid>
              </Grid>
            </form>
          </div>
          ) : null}
          {state === STATES.WAITING_FOR_USER_CONFIRMATION ? (
          <Grid container className={classes.form} spacing={2}>
            <Grid item xs={12}>
            <Typography variant="h2">Your password was succesfully reset</Typography>
              <Typography><Link href="/sigin">Click here to sign in</Link> using your new password.</Typography>
            </Grid>
          </Grid>
          ) : null}
        </PageContainer>
      </ContentContainer>
    </Container>
  );
}
