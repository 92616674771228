import { Container, ContentContainer, PageContainer, ContainedButton } from '../../theme.js'
import { makeStyles } from '@material-ui/core/styles';
import Auth from '@aws-amplify/auth'
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from "../../util/config";
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  formContainer: {
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    maxWidth: 280,
    textAlign: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const STATES = {
  WAITING_FOR_PASSWORD: 0,
  WAITING_FOR_USER_CONFIRMATION: 1
}

export default function ChangePassword() {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [state, setState] = React.useState(STATES.WAITING_FOR_PASSWORD);
  const [message, setMessage] = React.useState({content: '', severity: 'success'});
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const oldPasswordError = (oldPassword || '').length < 1;
  const newPasswordError = (newPassword || '').length < 1;
  const confirmNewPasswordError = (newPassword || '').length < 1;

  Auth.configure({
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(prevState => ({content: '', severity: prevState.severity}));
  };

  const handleChangePassword = (event, reason) => { 
    event.preventDefault();
    setSubmitted(true);
    if (oldPasswordError) {
      setMessage({content: "Please enter your old password", severity: "error"});
      return;
    }
    if (newPasswordError) {
      setMessage({content: "Please enter your new password", severity: "error"});
      return;
    }
    if (confirmNewPasswordError) {
      setMessage({content: "Please confirm your new password", severity: "error"});
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setMessage({content: 'Passwords do not match', severity: 'error'});
      return;
    }
    setLoading(true);
    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      setState(STATES.WAITING_FOR_USER_CONFIRMATION);
    })
    .catch(error => {
      setMessage({content: "There was a problem changing your password. " + error.message, severity: "error"});
    })
    .then(() => {
      setLoading(false);
    })
  };

  return (
    <Container>
      <ContentContainer>
        <PageContainer style={{textAlign: 'center'}}>
          <Snackbar open={message.content.length > 0} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message.severity}>
              <Typography>{message.content}</Typography>
            </MuiAlert>
          </Snackbar>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box style={{marginTop: 12, marginBottom: 20}}>
            <Typography variant="h3">Change Password</Typography>
          </Box>
          {state === STATES.WAITING_FOR_PASSWORD ? (
          <form noValidate autoComplete="off" className={classes.formContainer}>
            <Grid container className={classes.form} spacing={2}>
              <Grid item xs={12}>
                <Typography>Please enter in your current password followed by your new password below.</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth type="password" required id="oldPassword" label="Current Password" error={submitted && oldPasswordError} variant="outlined" value={oldPassword} onInput={e=>setOldPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth type="password" required id="newPassword" label="New Password" error={submitted && newPasswordError} variant="outlined" value={newPassword} onInput={e=>setNewPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth type="password" required id="newPassword" label="Confirm New Password" error={submitted && confirmNewPasswordError} variant="outlined" value={confirmNewPassword} onInput={e=>setConfirmNewPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <ContainedButton type="submit" variant="contained" color="primary" fullWidth onClick={handleChangePassword}>Change Password</ContainedButton>
              </Grid>
            </Grid>
          </form>
          ) : null}
          {state === STATES.WAITING_FOR_USER_CONFIRMATION ? (
          <Grid container className={classes.form} spacing={2}>
            <Grid item xs={12}>
              <Typography>Your password was succesfully reset.</Typography>
            </Grid>
          </Grid>
          ) : null}
        </PageContainer>
      </ContentContainer>
    </Container>
  );
}
