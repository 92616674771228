import { Container, ContentContainer, PageContainer, ContainedButton } from '../../theme.js'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import Auth from '@aws-amplify/auth'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from "../../util/config";
import constants from "../../util/constants";
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  formContainer: {
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    maxWidth: 280,
    textAlign: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SignUp() {
  let query = useQuery();
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [sfAccountId, setSFAccountId] = React.useState(query.get('accountId') || '');
  const [sfPartnerId, setSFPartnerId] = React.useState(query.get('partnerId') || '');
  const [message, setMessage] = React.useState({content: '', severity: 'success'});
  const [signingUp, setSigningUp] = React.useState(false);
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(prevState => ({content: '', severity: prevState.severity}));
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMessage({content: 'Passwords do not match', severity: 'error'});
      return;
    }
    setSigningUp(true);
    Auth.configure({
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    })
    Auth.signUp({username, password, attributes: { name: name, 'custom:sf_partner_id': sfPartnerId }, validationData: { 'sf_account_id': sfAccountId } })
    .then(res => {
      Auth.signIn(username, password).then(user => {
        setMessage({content: 'Successfully signed up', severity: 'success'});
        localStorage.setItem(constants.storage.ACCESS_TOKEN, user.signInUserSession.idToken.jwtToken); 
        history.push("/home")
      });
    }).catch(err => {
      setMessage({content: err.message.replace('PreSignUp failed with error ', ''), severity: 'error'});
    }).then(function() {
      setSigningUp(false);
    })
  }

  return (
    <Container>
      <ContentContainer>
        <PageContainer>
          <Snackbar open={message.content.length > 0} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message.severity}>
              <Typography>{message.content}</Typography>
            </MuiAlert>
          </Snackbar>
          <Backdrop className={classes.backdrop} open={signingUp}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form noValidate autoComplete="off" onSubmit={handleSubmit} className={classes.formContainer}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">Next Steps</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="name" label="Full Name" variant="outlined" value={name} onInput={e=>setName(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="username" label="Email Address" variant="outlined" value={username} onInput={e=>setUsername(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="password" label="Password" variant="outlined" type="password" value={password} onInput={e=>setPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="confirmPassword" label="Confirm Password" variant="outlined" type="password" value={confirmPassword} onInput={e=>setConfirmPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12} style={{display: 'none'}}>
                <TextField fullWidth id="sfAccountId" label="Account Id" variant="outlined" value={sfAccountId} onInput={e=>setSFAccountId(e.target.value)} />
              </Grid>
              <Grid item xs={12} style={{display: 'none'}}>
                <TextField fullWidth id="sfPartnerId" label="Partner Id" variant="outlined" value={sfPartnerId} onInput={e=>setSFPartnerId(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <ContainedButton type="submit" variant="contained" color="primary" fullWidth>Sign Up</ContainedButton>
              </Grid>
            </Grid>
          </form>
        </PageContainer>
      </ContentContainer>
    </Container>
  );
}
