import './App.css';
import { BrowserRouter as Router,Switch,Route } from "react-router-dom";
import ChangePassword from './components/pages/ChangePassword'
import Contact from "./components/pages/Contact"
import ForgotPassword from './components/pages/ForgotPassword'
import Header from "./components/pages/Header";
import HeaderNoAuth from "./components/pages/HeaderNoAuth";
import Home from './components/pages/Home'
import Link from '@material-ui/core/Link';
import { ThemeProvider } from '@material-ui/core/styles'
import Marketing from "./components/pages/Marketing"
import Profile from './components/pages/Profile'
import React from 'react';
import SignIn from "./components/pages/SignIn"
import SignUp from "./components/pages/SignUp"
import Training from "./components/pages/Training"
import Typography from '@material-ui/core/Typography';
import theme from './theme';
import { CssBaseline } from '@material-ui/core';
import Box from '@material-ui/core/Box';

function Copyright() {
  return (
    <Box style={{marginBottom: 40}}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://geoworkforcesolutions.com">
          Geo Limited
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Router>
          <Switch>
            <Route path="/profile">
              <Header />
              <Profile />
            </Route>
            <Route path="/home">
              <Header />
              <Home />
            </Route>
            <Route path="/marketing">
              <Header />
              <Marketing />
            </Route>
            <Route path="/training">
              <Header />
              <Training />
            </Route>
            <Route path="/contact">
              <Header />
              <Contact />
            </Route>
            <Route path="/change-password">
              <Header />
              <ChangePassword />
            </Route>
            <Route path="/forgot-password">
              <HeaderNoAuth />
              <ForgotPassword />
            </Route>
            <Route path="/signup">
              <HeaderNoAuth />
              <SignUp />
            </Route>
            <Route path="/forgot-password">
              <HeaderNoAuth />
              <ForgotPassword />
            </Route>
            <Route path="/">
              <HeaderNoAuth />
              <SignIn />
            </Route>
          </Switch>
        </Router>
        <Copyright />
      </div>
    </ThemeProvider>
  );
}
