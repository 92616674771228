import { lighten } from '@material-ui/core/styles';
import { withStyles , createMuiTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const colors = {
  accent: '#238dc1',
  accentDark: '#14709d',
  textPrimary: '#333333',
  textSecondary: '#757575',
  darkBackground: '#efefef',
  black: '#282828'
}

export const LogoTypography = withStyles((theme) => ({
  root: {
    fontFamily: 'TeXGyreAdventor-Bold',
    fontSize: 20
  }
}))(Typography);

export const AccentTypography = withStyles((theme) => ({
  root: {
    color: colors.accent
  }
}))(Typography);

export const Container = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(Box);

export const ContentContainer = withStyles((theme) => ({
  root: {
    textAlign: 'left',
    width: 960
  }
}))(Box);

export const PageContainer = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  }
}))(ContentContainer);

export const HeaderLink = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))(Link);

export const Loading = withStyles((theme) => ({
  root: {
    margin: theme.spacing(20)
  }
}))(CircularProgress);

export const TextButton = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.9
  }
}))(Button);

export const ContainedButton = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Lato',
    letterSpacing: 1.25,
    backgroundColor: colors.accent,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: lighten(colors.accent, 0.25),
      boxShadow: 'none'
    }
  }
}))(Button);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.accentDark
    },
    secondary: {
      main: colors.textSecondary
    },
    text:{
      primary: colors.textPrimary,
      secondary: colors.textSecondary
    }
  },
  typography: {
    fontFamily: [
      'Lato'
    ].join(','),
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle: {

    },
    h1: {
      fontSize: 48,
      fontWeight: 900
    },
    h2: {
      fontSize: 26,
      lineHeight: '72px',
      fontWeight: 700,
    },
    h3: {
      fontSize: 22,
      fontWeight: 400
    },
    h4: {
      fontSize: 18,
      fontWeight: 400
    }
  }
});

export default theme;
