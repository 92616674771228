import { Container, ContentContainer, AccentTypography, Loading, PageContainer, ContainedButton, colors } from '../../theme.js';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import * as api from "../../util/api"
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Grid from '@material-ui/core/Grid';
import illustration from './../../images/illustration.svg';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types'
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SwipeableViews from 'react-swipeable-views';
import Link from '@material-ui/core/Link';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  darkContainer: {
    backgroundColor: colors.darkBackground
  },
  invitationLinkContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 200,
    display: 'flex',
    flexDirection: 'column'
  },
  spaced: {
    margin: theme.spacing(2)
  },
  spacedContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  spacedContentRight: {
    marginRight: theme.spacing(2)
  },
  spacedContentLeft: {
    marginLeft: theme.spacing(2)
  },
  spacedContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: '#fafbfc'
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  illustration: {
    backgroundImage: `url(${illustration})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    width: 1080,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex'
  },
  mainPoint: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(2)
  },
  mainPointFirst: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(2),
    marginTop: 0
  },
  mainPointLast: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(2),
    marginBottom: 0
  },
  mainPoints: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  mainPointsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tabPanel: {
    margin: 0,
    padding: 0
  },
  tabAppBar: {
    background: 'none',
    boxShadow: 'none',
    margin: 0,
    padding: 0
  },
  paper: {
    borderRadius: 0,
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  spacedTop: {
    marginTop: theme.spacing(1)
  },
  spacedTopx2: {
    marginTop: theme.spacing(2)
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 10,
    color: '#333333',
    textTransform: 'uppercase'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const ACTIVE_ACCOUNT_STATUSES = ['Trial', 'Trial Extended', 'Paying', 'TEST'];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{margin: 2}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #979797',
    marginBottom: 0,
    paddingBottom: 0
  }
})(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    marginRight: 2,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontFamily: ['Lato'].join(','),
    fontSize: 18,
    fontWeight: 500,
    background: '#e0e0e0',
    color: '#757575',
    '&$selected': {
      color: '#ffffff',
      background: colors.accent
    },
    '&$active': {
      color: '#ffffff',
      background: colors.accent
    }
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const [takeOverError, setTakeOverError] = React.useState(false);
  const [takeOverFetching, setTakeOverFetching] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [dataError, setDataError] = React.useState(null);
  const [dataFetching, setDataFetching] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleTabChangeIndex = (index) => {
    setTab(index);
  };

  const handleTakeOver = async (accountId) => {
    setTakeOverFetching(true);
    api.getTakeOver(accountId).then(function (response) {
      const takeOverUrl = (response.data || {})['take_over_url'];
      if (takeOverUrl) {
        window.open(takeOverUrl, '_blank');
      } else {
        setTakeOverError(true);
      }
    }).catch(function (error) {
      setTakeOverError(true);
    }).then(function () {
      setTakeOverFetching(false);
    });
  };

  const token = api.getDecodedAccessToken();
  if (!token) {
    window.location.href = "/signin";
  }
  const firstName = token.name.split(' ')[0];

  React.useEffect(() => {
    if (!dataFetching && !dataError && !data) {
      setDataFetching(true);
      api.getPartner().then(function (response) {
        if (response.data === null) {
          api.removeAccessToken();
          window.location.href = "/signin";
        } else {
          setData(response.data);
        }
      }).catch(function (error) {
        if (error.name !== 'AbortError') {
          setDataError(error);
        }
      }).then(function () {
        setDataFetching(false);
      });
    }
  }, [data, dataError, dataFetching]);

  if (data) {
    return (
      <div>
        <Backdrop className={classes.backdrop} open={takeOverFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container>
          <ContentContainer className={classes.illustration}>
            <PageContainer>
              <Snackbar open={takeOverError} autoHideDuration={6000} onClose={() => setTakeOverError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <MuiAlert elevation={6} variant="filled" onClose={() => setTakeOverError(false)} severity="error">
                  <Typography>There was a problem trying to take over the account. Please try again later.</Typography>
                </MuiAlert>
              </Snackbar>
              <Snackbar open={copied} autoHideDuration={6000} onClose={() => setCopied(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <MuiAlert elevation={6} variant="filled" onClose={() => setCopied(false)} severity="info">
                  <Typography>Invitation link copied to clipboard.</Typography>
                </MuiAlert>
              </Snackbar>
              <AccentTypography variant="h1">Hi {firstName}</AccentTypography>
              <Typography variant="h2">Welcome to the Geo Partner Portal.</Typography>
              <Grid container className={classes.mainPointsContainer}>
                <Grid item style={{ width: 4, maxWidth: 4, backgroundColor: colors.accent }} />
                <Grid item>
                  <ol className={classes.mainPoints}>
                    <li><Typography className={classes.mainPointFirst} color="secondary">Here you’ll find your Unique Referral link & Dashboard of your referred customers</Typography></li>
                    <li><Typography className={classes.mainPoint} color="secondary">Setup/manage your partner profile that is promoted in our Partner Directory; and</Typography></li>
                    <li><Typography className={classes.mainPointLast} color="secondary">Promotional Assets / Collateral to help you refer customers.</Typography></li>
                  </ol>
                </Grid>
              </Grid>
            </PageContainer>
          </ContentContainer>
        </Container>
        <Container className={classes.darkContainer}>
          <PageContainer className={classes.spacedContainer}>
            <Box className={classes.spacedContentRight} style={{textAlign: 'center', maxWidth: 800, margin: '0 auto'}}>
              <Typography variant="h2">How to refer customers and create additional value for your business</Typography>
              <Typography>There are a number of ways to refer customers from this portal – Simply use one of the following 3 options which will track and attribute referrals to you.</Typography>
              <Typography className={classes.spacedTop}>When a referred customer commences a trial, you will see them in the 'Active Customers' table below. If for any reason you do not see a referred customer please <Link color="primary" href='https://geoworkforcesolutions.com/contact-us-new/' target="_blank">contact us.</Link></Typography>
            </Box>
          </PageContainer>
        </Container>
        <Container className={classes.darkContainer}>
          <PageContainer className={classes.spacedContainer} style={{width: 1080, marginBottom: theme.spacing(6)}}>
              <Grid container>
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                    <Typography variant="subtitle1" color='secondary' className={classes.subtitle}>Option 1</Typography>
                    <Typography variant="h4" style={{ color: colors.black }}>Book a demo for your customer</Typography>
                    <Typography className={classes.spacedTopx2}>Click on the Book a Demo button, fill out your clients details and book in a time slot. It will go straight into the diary of one of our skilled sales team members.<br/><br/></Typography>
                    <ContainedButton className={classes.spacedTopx2} color="primary" variant="contained" target='_blank' href={`https://geoworkforcesolutions.com/book-a-demo/?${data.invitationLink.split('?')[1]}`} style={{marginRight: theme.spacing(2)}}>Book a Demo</ContainedButton>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                  <Typography variant="subtitle1" color='secondary' className={classes.subtitle}>Option 2</Typography>
                    <Typography variant="h4" style={{ color: colors.black }}>Start a free 14 day trial for a customer</Typography>
                    <Typography className={classes.spacedTopx2}>Clicking on this link will take you straight to our sign up page where you can create a free 14 day trial for your customer.<br/><br/><br/></Typography>
                    <ContainedButton className={classes.spacedTopx2} color="primary" variant="contained" target='_blank' href={`https://geoworkforcesolutions.com/?${data.invitationLink.split('?')[1]}#signup`}>Start a Free Trial</ContainedButton>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                  <Typography variant="subtitle1" color='secondary' className={classes.subtitle}>Option 3</Typography>
                    <Typography variant="h4" style={{ color: colors.black }}>Use your unique referral link</Typography>
                    <Typography className={classes.spacedTopx2}>This link is unique to you, and can be placed on your website, emails, blog posts, social media channels and more. This will direct customers to our website and attribute them to you. Copy the link below.</Typography>
                    <ContainedButton className={classes.spacedTopx2} variant="contained" color="primary" startIcon={<FileCopyOutlinedIcon />} onClick={() => { navigator.clipboard.writeText(data.invitationLink); setCopied(true); }}>Copy Invite Link</ContainedButton>
                  </Paper>
                </Grid>
              </Grid>
          </PageContainer>
        </Container>
        <Container>
          <PageContainer style={{marginTop: theme.spacing(6)}}>
            <AppBar position="static" color="default" className={classes.tabAppBar}>
              <StyledTabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{style: {display: "none"}}}
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <StyledTab style={{maxWidth: 240}} label="Active Customers" {...a11yProps(0)} />
                <StyledTab style={{maxWidth: 240}} label="Inactive Customers" {...a11yProps(1)} />
                <StyledTab style={{maxWidth: 240}} label="Total Referral Fees Paid" {...a11yProps(2)} />
              </StyledTabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tab}
              onChangeIndex={handleTabChangeIndex}>
              <TabPanel value={tab} index={0} dir={theme.direction} className={classes.tabPanel}>
                <TableContainer className={classes.table} component={Paper}>
                  <Table aria-label="active referral table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} colSpan={6}>
                          <Typography>
                            Here are the Active customers you’ve referred to Geo. These are either Paying Customers, or customers on a free 14 day Trial. Once a customer has been a paying Geo customer for 3 months, your referral fee will be paid.
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableHeader} align="left">Company Name</TableCell>
                        <TableCell className={classes.tableHeader} align="left">Referral Date</TableCell>
                        <TableCell className={classes.tableHeader} align="left">Status</TableCell>
                        <TableCell className={classes.tableHeader} align="left">Active Date</TableCell>
                        <TableCell className={classes.tableHeader} align="left">Referral Fee Paid</TableCell>
                        <TableCell className={classes.tableHeader} align="right">Take&nbsp;Over</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.childAccounts.filter(r => ACTIVE_ACCOUNT_STATUSES.indexOf(r.status) !== -1).length > 0 ? (
                        data.childAccounts.filter(r => ACTIVE_ACCOUNT_STATUSES.indexOf(r.status) !== -1).map((row) => (
                          <TableRow key={row.name}>
                            <TableCell lign="left" scope="row">{row.name}</TableCell>
                            <TableCell align="left">{Moment(row.createdDate).format("Do MMMM YYYY")}</TableCell>
                            <TableCell align="left">{row.status}</TableCell>
                            <TableCell align="left">{row.firstPaymentDate ? Moment(row.firstPaymentDate).format("Do MMMM YYYY") : "N/A"}</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="right">
                              <IconButton color="default" aria-label="take over" component="span" onClick={() => handleTakeOver(row.accountId)}>
                                <ExitToAppIcon/>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))) : (
                        <TableRow>
                          <TableCell colSpan={6} style={{ textAlign: 'center' }} ><Typography color="textSecondary">No Customers</Typography></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={tab} index={1} dir={theme.direction} className={classes.tabPanel}>
                <TableContainer className={classes.table} component={Paper}>
                  <Table aria-label="referral table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} colSpan={5}>
                          <Typography>
                            Below are customers that are no longer active on the Geo platform.
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.tableHeader}>Company Name</TableCell>
                        <TableCell align="left" className={classes.tableHeader}>Referral Date</TableCell>
                        <TableCell align="left" className={classes.tableHeader}>Status</TableCell>
                        <TableCell align="left" className={classes.tableHeader}>Last Payment Date</TableCell>
                        <TableCell align="right" className={classes.tableHeader}>Take&nbsp;Over</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.childAccounts.filter(r => ACTIVE_ACCOUNT_STATUSES.indexOf(r.status) === -1).length ? (data.childAccounts.filter(r => ACTIVE_ACCOUNT_STATUSES.indexOf(r.status) === -1).map((row) => (
                        <TableRow key={row.name}>
                          <TableCell align="left" scope="row">{row.name}</TableCell>
                          <TableCell align="left">{Moment(row.createdDate).format("Do MMMM YYYY")}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                          <TableCell align="left">{row.firstPaymentDate ? Moment(row.firstPaymentDate).format("Do MMMM YYYY") : "N/A"}</TableCell>
                          <TableCell align="right">
                            <IconButton color="default" aria-label="take over" component="span" onClick={() => handleTakeOver(row.accountId)}>
                              <ExitToAppIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))) : (
                        <TableRow>
                          <TableCell colSpan={5} style={{ textAlign: 'center' }} ><Typography color="textSecondary">No Customers</Typography></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={tab} index={2} dir={theme.direction} className={classes.tabPanel}>
                <TableContainer className={classes.table} component={Paper}>
                  <Table aria-label="referral table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} colSpan={4}>
                          <Typography>
                            The total amount of referral fees paid to date.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: 'center' }} ><Typography color="textSecondary">TBD</Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </SwipeableViews>
          </PageContainer>
        </Container>
      </div>
    );
  } else {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
}
