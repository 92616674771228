import { Container, ContentContainer, PageContainer, ContainedButton } from '../../theme.js';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import * as api from '../../util/api';
import Auth from '@aws-amplify/auth'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from "../../util/config";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    maxWidth: 280,
    textAlign: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [message, setMessage] = React.useState({content: '', severity: 'success'});
  const [signingIn, setSigningIn] = React.useState(false);
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(prevState => ({content: '', severity: prevState.severity}));
  };

  function handleSubmit(event) {
    event.preventDefault();
    setSigningIn(true);
    Auth.configure({
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    })
    Auth.signIn(username, password).then(user => {
      setMessage({content: 'Successfully signed in', severity: 'success'});
      api.setAccessToken(user.signInUserSession.idToken.jwtToken);
      history.push("/home");
    }).catch(err => {
      setMessage({content: err.message, severity: 'error'});
    }).then(function() {
      setSigningIn(false);
    })
  }

  return (
    <Container>
      <ContentContainer>
        <PageContainer style={{textAlign: 'center'}}>
          <Snackbar open={message.content.length > 0} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message.severity}>
              <Typography>{message.content}</Typography>
            </MuiAlert>
          </Snackbar>
          <Backdrop className={classes.backdrop} open={signingIn}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography variant="h2">Welcome to the Partner Portal</Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container className={classes.formContainer} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">Sign In</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="username" label="Email Address" variant="outlined" value={username} onInput={e=>setUsername(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="password" label="Password" variant="outlined" type="password" value={password} onInput={e=>setPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <ContainedButton type="submit" variant="contained" color="primary" fullWidth>Login</ContainedButton>
              </Grid>
              <Grid item xs={12}>
                <Link href="/forgot-password"><Typography>Forgot your password?</Typography></Link>
              </Grid>
            </Grid>
          </form>
          <Typography>Not a partner? <Link href="https://geoworkforcesolutions.com/partnerprogram">Find out more information here</Link></Typography>
        </PageContainer>
      </ContentContainer>
    </Container>
  );
}
