import { Container, ContentContainer, AccentTypography, PageContainer, TextButton } from '../../theme.js';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    padding: theme.spacing(2)
  },
  spacedTop: {
    marginTop: theme.spacing(1)
  },
  spacedTopx2: {
    marginTop: theme.spacing(2)
  }
}));

export default function Contact() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container>
      <ContentContainer>
        <PageContainer style={{marginBottom: theme.spacing(1)}}>
          <AccentTypography variant="h1">Contact Us</AccentTypography>
        </PageContainer>
        <PageContainer style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(4)}}>
          <Typography>We’re here to work together to help grow our clients businesses.</Typography>
        </PageContainer>
        <PageContainer style={{marginTop: 0}}>
          <Grid container>
            <Grid item xs={6}>
              <Paper className={classes.paper} style={{marginRight: theme.spacing(1), marginBottom: theme.spacing(2)}}>
                <Typography variant="h3">Partner Manager</Typography>
                <Typography variant="subtitle1" color='secondary'>Angus Leslie</Typography>
                <Typography className={classes.spacedTop}>If you need help on the partner program, please contact Angus Leslie on <Link href='mailto:angus.leslie@geoop.com'>angus.leslie@geoop.com</Link>. You can also arrange time to discuss how to maximise the marketing assets or program in general, below.<br/><br/></Typography>
                <TextButton className={classes.spacedTopx2} color="primary" target='_blank' href='https://calendly.com/geo-partners/30min'>Arrange A Meeting</TextButton>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper} style={{marginRight: theme.spacing(1), marginBottom: theme.spacing(2)}}>
                <Typography variant="h3">Geo Support</Typography>
                <Typography variant="subtitle1" color='secondary'>&nbsp;&nbsp;</Typography>
                <Typography className={classes.spacedTop}>Check out our Geo Knowledgebase.</Typography>
                <Typography>If you need additional product, please contact support via your preferred method (Call / Email / Chat). Simply click Contact Us below.<br/><br/></Typography>
                <TextButton className={classes.spacedTopx2} color="primary" target='_blank' href='https://support.geoop.com/' style={{marginRight: theme.spacing(2)}}>Geo Knowledgebase</TextButton>
                <TextButton className={classes.spacedTopx2} color="primary" target='_blank' href='https://geoworkforcesolutions.com/contact-us-new/'>Contact Us</TextButton>
              </Paper>
            </Grid>
          </Grid>
          <PageContainer style={{marginBottom: theme.spacing(1)}}>
            <Typography variant="h3" className={classes.subHeader}>Other Useful Information</Typography>
            <TextButton className={classes.spacedTopx2} color="primary" target='_blank' href='/resources/GeoNext Partner Handbook.pdf'>GeoNext Partner Handbook</TextButton><br/>
          </PageContainer>
        </PageContainer>
      </ContentContainer>
    </Container>
  );
}
