import { Container, AccentTypography, PageContainer, TextButton, colors } from '../../theme.js';
import { makeStyles } from '@material-ui/core/styles';
import illustration from './../../images/illustration.svg';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  darkContainer: {
    backgroundColor: colors.darkBackground
  },
  invitationLinkContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 200,
    display: 'flex',
    flexDirection: 'column'
  },
  spaced: {
    margin: theme.spacing(2)
  },
  spacedContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  spacedContentRight: {
    marginRight: theme.spacing(2)
  },
  spacedContentLeft: {
    marginLeft: theme.spacing(2)
  },
  spacedContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableHeader: {
    verticalAlign: 'top',
    fontWeight: 'bold',
    backgroundColor: '#fafbfc'
  },
  tableCell: {
    verticalAlign: 'top'
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  illustration: {
    backgroundImage: `url(${illustration})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    width: 1080,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex'
  },
  mainPoint: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(2)
  },
  mainPointFirst: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(2),
    marginTop: 0
  },
  mainPointLast: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(2),
    marginBottom: 0
  },
  mainPoints: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  mainPointsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tabPanel: {
    margin: 0,
    padding: 0
  },
  tabAppBar: {
    background: 'none',
    boxShadow: 'none',
    margin: 0,
    padding: 0
  },
  subHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

export default function Training() {
  const classes = useStyles();

  return (
    <Container>
      <PageContainer>
        <AccentTypography variant="h1">Product Training</AccentTypography>
        <Typography className={classes.subHeader}>We run training five times a week to help you become a GeoNext product expert. Below you will find a range of different webinar options where our head trainer will be running sessions which will also include a live Q&A at the end of each session.</Typography>
        <Typography variant="h2">Training Schedule, April - June  2021</Typography>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="training-1 table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} colSpan={4}>
                  <Typography>Onboarding / Training Webinars: (1 hour session)</Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableHeader}>Webinar</TableCell>
                <TableCell className={classes.tableHeader}>What is it</TableCell>
                <TableCell className={classes.tableHeader}>Perfect for</TableCell>
                <TableCell className={classes.tableHeader} style={{minWidth: 240}}>Register</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell className={classes.tableCell}>
                  <Typography>Account Setup and Admin Console Training</Typography>
                  <Typography>(1 hour)</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>How to get started, account setup and a deep dive into how to use the desktop console as an Admin User, including:</Typography>
                  <ul>
                    <li><Typography>Add Staff</Typography></li>
                    <li><Typography>Integration (Accounting Software)</Typography></li>
                    <li><Typography>Add/Import Clients</Typography></li>
                    <li><Typography>Add/Import Materials</Typography></li>
                    <li><Typography>Add/Import Labour</Typography></li>
                    <li><Typography>Set up Job statuses</Typography></li>
                    <li><Typography>Quoting</Typography></li>
                    <li><Typography>Job Creation</Typography></li>
                    <li><Typography>Invoicing and Payment</Typography></li>
                    <li><Typography>Scheduler</Typography></li>
                    <li><Typography>Geo Map Overview</Typography></li>
                  </ul>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>Admin Users</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextButton color="primary" href="https://us02web.zoom.us/webinar/register/WN_-bUWOY9IR1ONvplk-gMi4A" target="_blank">Tuesday 12pm AEST</TextButton>
                  <TextButton color="primary" href="https://us02web.zoom.us/webinar/register/WN_kLtDgW60TM-My0InW-Mh-w" target="_blank">Tuesday 6pm AEST</TextButton>
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.tableCell}>
                  <Typography>Mobile Apps Training: iOS and Android</Typography>
                  <Typography>(1 hour)</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>Deep dive on how the mobile apps work, including:</Typography>
                  <ul>
                    <li><Typography>Jobs</Typography></li>
                    <li><Typography>Invoices</Typography></li>
                    <li><Typography>Clients</Typography></li>
                    <li><Typography>Staff</Typography></li>
                    <li><Typography>Billables</Typography></li>
                    <li><Typography>Settings</Typography></li>
                  </ul>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>Mobile Staff, Admin Users</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextButton color="primary" href="https://us02web.zoom.us/webinar/register/WN_-bUWOY9IR1ONvplk-gMi4A" target="_blank">Thursday 12pm AEST</TextButton>
                  <TextButton color="primary" href="https://us02web.zoom.us/webinar/register/WN_kLtDgW60TM-My0InW-Mh-w" target="_blank">Thursday 6pm AEST</TextButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="training-2 table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} colSpan={4}>
                  <Typography>Onboarding / Training Webinars: Full Training</Typography>
                  <Typography>(2 hours)</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableHeader}>Webinar</TableCell>
                <TableCell className={classes.tableHeader}>What is it</TableCell>
                <TableCell className={classes.tableHeader}>Perfect for</TableCell>
                <TableCell className={classes.tableHeader} style={{minWidth: 240}}>Register</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell className={classes.tableCell}>
                  <Typography>Account Setup and Admin Console Training + Mobile Apps Training (2 hours)</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>The whole shebang! In this webinar you’ll work through getting your account setup, a deep dive into the desktop console as well as how the mobile apps work. Session includes: </Typography>
                  <ul>
                    <li><Typography>Add Staff</Typography></li>
                    <li><Typography>Integration (Accounting Software)</Typography></li>
                    <li><Typography>Add/Import Clients</Typography></li>
                    <li><Typography>Add/Import Materials</Typography></li>
                    <li><Typography>Add/Import Labour</Typography></li>
                    <li><Typography>Set up Job statuses</Typography></li>
                    <li><Typography>Quoting</Typography></li>
                    <li><Typography>Job Creation</Typography></li>
                    <li><Typography>Invoicing and Payment</Typography></li>
                    <li><Typography>Scheduler</Typography></li>
                    <li><Typography>Geo Map Overview</Typography></li>
                  </ul>
                  <Typography>iOS / Android Apps:</Typography>
                  <ul>
                    <li><Typography>Jobs</Typography></li>
                    <li><Typography>Invoices</Typography></li>
                    <li><Typography>Clients</Typography></li>
                    <li><Typography>Staff</Typography></li>
                    <li><Typography>Billables</Typography></li>
                    <li><Typography>Settings</Typography></li>
                  </ul>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>Admin Users</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                <TextButton color="primary" href="https://us02web.zoom.us/webinar/register/WN_oxbOQfzUQYSXePpb-lkylA" target="_blank">Wednesday 9am AEST</TextButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </PageContainer>
    </Container>
  );
}
