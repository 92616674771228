import { Link as RouterLink, useLocation } from "react-router-dom";
import { LogoTypography, HeaderLink, colors } from '../../theme.js';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flewGrow: 1
  },
  boldText: {
    fontWeight: 700
  },
  inactiveText: {
    fontWeight: 700
  },
  appBar: {
    boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
    width: '100%',
    height: 69
  }
}));


export default function HeaderNoAuth() {
  // Recover selected tab.
  const match = useLocation();
  const classes = useStyles();

  const links = [
    { label: 'Sign In', location: '/signin' }
  ];

  return (
    <div className={classes.root}>
      <table className={classes.appBar}>
        <tbody>
          <tr>
            <td style={{width: 200, minWidth: 200, maxWidth: 200}}>
              <LogoTypography style={{marginTop: '-4px'}}>
                <span style={{color: colors.accent}}>Geo</span> Partner Portal
              </LogoTypography>
            </td>
            <td style={{textAlign: 'center'}}>
              <div style={{maxWidth: 1096, margin: '0 auto', textAlign: 'left', marginTop: '-1px'}}>
                {links.map((l) => (
                  <HeaderLink key={l.location} color={l.location === match.pathname ? 'primary' : 'secondary'} style={l.location === match.pathname ? { fontWeight: 700 } : {}} component={RouterLink} to={`${l.location}`}>{l.label}</HeaderLink>
                ))}
              </div>
            </td>
            <td style={{width: 64, minWidth: 64, maxWidth: 64, textAlign: 'right', paddingRight: 10}}>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
