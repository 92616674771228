const dev = {
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_JCPNF0n6T",
    APP_CLIENT_ID: "4g28dl9bck0v0nmcdokme1ev54"
  },
  api: {
    BASE_URL: "https://api-dev.partnerportal.geoworkforcesolutions.com"
  },
  google: {
    API_KEY: "AIzaSyDyjFr05AEYFxNgeiACyQu_6KdeW8UyMZQ"
  }
};

const prod = {
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_YaXO1hUaS",
    APP_CLIENT_ID: "75qmphe32br4aoc32ac4oo14gk"
  },
  api: {
    BASE_URL: "https://api.partnerportal.geoworkforcesolutions.com"
  },
  google: {
    API_KEY: "AIzaSyDyjFr05AEYFxNgeiACyQu_6KdeW8UyMZQ"
  }
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;


const exportConfig = {
  ...config
};

export default exportConfig;
