import constants from "./constants";
import config from "./config";
import axios from 'axios';
import jwt_decode from "jwt-decode";

export function setAccessToken(accessToken) {
  localStorage.setItem(constants.storage.ACCESS_TOKEN, accessToken);
}

export function getAccessToken() {
  return localStorage.getItem(constants.storage.ACCESS_TOKEN);
}

export function getDecodedAccessToken() {
  const token = getAccessToken();
  try {
    if (!token) {
      return null;
    } else {
      return jwt_decode(token);
    }
  } catch (err) {
    removeAccessToken();
    return null;
  }
}

export function removeAccessToken() {
  localStorage.removeItem(constants.storage.ACCESS_TOKEN);
}

export function getTakeOver(accountId) {
  const instance = axios.create({
    baseURL: config.api.BASE_URL,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem(constants.storage.ACCESS_TOKEN)
    }
  })
  return instance.get('take_over', { params: { account: accountId } }).catch(function (error) {
    if (error.message === "Network Error" || error.response.status === 401) {
      removeAccessToken();
      window.location.href = "/signin";
    } else {
      throw error;
    }
  });
}

export function getPartner() {
  const instance = axios.create({
    baseURL: config.api.BASE_URL,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem(constants.storage.ACCESS_TOKEN)
    }
  })
  return instance.get("partner").catch(function (error) {
    if (error.message === "Network Error" || error.response.status === 401) {
      removeAccessToken();
      window.location.href = "/signin";
    } else {
      throw error;
    }
  });
}

export function updatePartnerLogo(type) {
  const instance = axios.create({
    baseURL: config.api.BASE_URL,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem(constants.storage.ACCESS_TOKEN)
    }
  })

  return instance.post(`partner/logo?type=${type}`).catch(function (error) {
    if (error.message === "Network Error" || error.response.status === 401) {
      removeAccessToken();
      window.location.href = "/signin";
    } else {
      throw error;
    }
  });
}

export function updatePartner(data) {
  const instance = axios.create({
    baseURL: config.api.BASE_URL,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem(constants.storage.ACCESS_TOKEN)
    }
  })
  return instance.patch("partner", data).catch(function (error) {
    if (error.message === "Network Error" || error.response.status === 401) {
      removeAccessToken();
      window.location.href = "/signin";
    } else {
      throw error;
    }
  });
}

export function getPartnerMetaData() {
  const instance = axios.create({
    baseURL: config.api.BASE_URL,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem(constants.storage.ACCESS_TOKEN)
    }
  })
  return instance.get("partner/metadata").catch(function (error) {
    if (error.message === "Network Error" || error.response.status === 401) {
      removeAccessToken();
      window.location.href = "/signin";
    } else {
      throw error;
    }
  });
}
